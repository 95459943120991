import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ActivityListItem from "../ActivityListItem";
import { useParams, useRouteMatch, Link } from "react-router-dom";
//import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import callApi, { useSWRApi } from "../../Helpers/callApi";
import { mutate } from "swr";
import Pager from "../../Shared/Pager";
import { useAddToast } from "../../Context/ToastContext";
import AddActivityModal from "./AddActivityModal";
import EditActivityModal from "./EditActivityModal";
import ShareActivityModal from "./ShareActivityModal";
import ShareActivitiesModal from "./ShareActivitiesModal";
import usePortfolioService from "../../Services/usePortfolioService";
import useActivityService from "../../Services/useActivityService";
import ShareActivityModalConfirmation from "./ShareActivityModalConfirmation";
import NestedPortfolioActivity from "./NestedPortfolioActivity";
import NestedPortfolioModalConfirmation from "./NestedPortfolioModalConfirmation";
import RequestAccessModalConfirmation from "./RequestAccessModalConfirmation";
import NestingPortfolioPopover from "../NestingPortfolioPopover";
import RemoveNestedPortfolioModalConfirmation from "./RemoveNestedPortfolioModalConfirmation";
import Collapsible from "./../../Shared/Collapsible";

export default function ActivityList({
  isUserPortfolioOwner,
  groupActivity,
  nestedPortfolioActivities,
  portfolio,
  setNestedPortfolioRemovedorAdded,
  nestedPortfolioRemovedorAdded,
}) {
  const [showNestingError, setShowNestingError] = useState(false);
  const pageSize = 20;
  const [page, setPage] = useState(1);
  const { portfolioId } = useParams();
  const [searchString, setSearchString] = useState("");
  const addToast = useAddToast();
  const match = useRouteMatch();
  const [
    previouslySharedPortfoliosOfActivity,
    setPreviouslySharedPortfoliosOfActivity,
  ] = useState([]);

  let { data: activitiesData, isLoading: isLoadingActivities } = useSWRApi(
    `portfolios/${portfolioId}/activities?searchString=${searchString}&page=${page}&pagesize=${pageSize}`
  );

  //let activities = activitiesData ? activitiesData.items : [];
  const thisPortfolioActivities = activitiesData
    ? activitiesData.items.filter((x) => x.isShared === false)
    : [];
  const sharedPortfolios = activitiesData
    ? [...new Set(activitiesData.items.filter((x) => x.isShared === true))]
    : [];
  let uniquePortfolioNames = sharedPortfolios
    ? [...new Set(sharedPortfolios.map((x) => x.originatingPortfolioTitle))]
    : [];

  uniquePortfolioNames = uniquePortfolioNames.sort((a, b) =>
    a.localeCompare(b)
  );

  let totalItemCount = activitiesData ? activitiesData.totalItemCount : 0;

  const [isAddActivityModalOpen, setIsAddActivityModalOpen] = useState(false);
  const [isDeleteActivityModalOpen, setIsDeleteActivityModalOpen] =
    useState(false);
  const [activityToBeDeleted, setActivityToBeDeleted] = useState(null);
  const [activityToBeRemoved, setActivityToBeRemoved] = useState(null);
  const [isRemoveActivityModalOpen, setIsRemoveActivityModalOpen] =
    useState(false);
  const [isEditActivityModalOpen, setIsEditActivityModalOpen] = useState(false);
  const [activityToBeEdited, setActivityToBeEdited] = useState(null);
  const [isShareActivityModalOpen, setIsShareActivityModalOpen] =
    useState(false);
  const [
    isShareActivityModalOpenConfirmation,
    setIsShareActivityModalOpenConfirmation,
  ] = useState(false);

  const [activityToBeShared, setActivityToBeShared] = useState(null);
  const [isShareActivitiesModalOpen, setIsShareActivitiesModalOpen] =
    useState(false);
  const [portfolioUniqueTitle, setPortfolioUniqueTitle] = useState(null);
  const [
    nestingPortfolioModalConfirmationOpen,
    setNestingPortfolioModalConfirmationOpen,
  ] = useState(false);
  const [portfolioUniqueTitleForNesting, setPortfolioUniqueTitleForNesting] =
    useState(null);
  const [portfolioTitleForNesting, setPortfolioTitleForNesting] =
    useState(null);
  const [
    requestAccessModalConfirmationOpen,
    setRequestAccessModalConfirmationOpen,
  ] = useState(false);
  const [
    requestAccessModalConfirmationOpenForPortfolio,
    setRequestAccessModalConfirmationOpenForPortfolio,
  ] = useState(false);

  const [
    isRemoveingNestingPortfolioModalOpenConfirmation,
    setIsRemoveingNestingPortfolioModalOpenConfirmation,
  ] = useState(false);

  const [nestingPortfolioIdForRemove, setNestingPortfolioIdForRemove] =
    useState(null);
  const [portfolioRemovingFrom, setPortfolioRemovingFrom] = useState("");

  const [requestActivityId, setRequestActivityId] = useState(0);
  const [requestPortfolioId, setRequestPortfolioId] = useState(0);

  const { createActivity } = usePortfolioService();
  const { editActivity, deleteActivity } = useActivityService();

  const handleSetEditedActivityProperty = (property, value) => {
    setActivityToBeEdited((prevState) => ({
      ...prevState,
      [property]: value,
    }));
  };

  const handleEditActivityModalClose = () => {
    setActivityToBeEdited(null);
    setIsEditActivityModalOpen(false);
  };
  const handleAddActivityModalOpen = () => setIsAddActivityModalOpen(true);
  const handleAddActivityModalClose = () => setIsAddActivityModalOpen(false);
  const handleClickDeleteActivity = (activity) => {
    setIsDeleteActivityModalOpen(true);
    setActivityToBeDeleted(activity);
  };
  const handleClickEditActivity = (activity) => {
    setIsEditActivityModalOpen(true);
    setActivityToBeEdited(activity);
  };
  const handleClickShareActivity = async (activity) => {
    setIsShareActivityModalOpen(true);
    setActivityToBeShared(activity);

    const response = await callApi(
      `portfolios/${activity.id}/getpreviouslysharedportfolios`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const result = await response.json();
    if (response.ok) {
      setPreviouslySharedPortfoliosOfActivity(result);
    }
  };
  const handleClickRemoveActivity = async (activity) => {
    setIsRemoveActivityModalOpen(true);
    setActivityToBeRemoved(activity);
  };

  const handleGotoActivityHomePortfolio = async (activity) => {
    window.location.replace(`/${activity.rootPortfolioId}/activities`);
  };

  const handleClickGotoHomePortfolio = async (item) => {
    window.location.replace(`/${item.portfolioViewModel.id}/activities`);
  };

  const handleRemoveActivityClose = () => {
    setIsRemoveActivityModalOpen(false);
    setActivityToBeRemoved(null);
  };
  const handleShareActivitiesModalClose = () => {
    setIsShareActivitiesModalOpen(false);
  };
  const handleShareActivitiesModalOpen = () => {
    setIsShareActivitiesModalOpen(true);
  };
  const handleDeleteActivityModalClose = () => {
    setIsDeleteActivityModalOpen(false);
    setActivityToBeDeleted(null);
  };

  const handleEditActivitySubmit = async () => {
    const response = await editActivity(activityToBeEdited);

    if (response.ok) {
      mutate(
        `portfolios/${portfolioId}/activities?searchString=${searchString}&page=${page}&pagesize=${pageSize}`
      );

      mutate(`portfolios/${portfolioId}/allresults`);
      mutate(`portfolios/allactivities/${portfolioId}`);
      mutate(
        `portfolios/${portfolioId}/results?page=${page}&pageSize=${pageSize}&searchString=${searchString}`
      );

      handleEditActivityModalClose();
    }
  };

  const handleNewActivitySubmit = async (title, description, ampIdentifier) => {
    let response = await createActivity(
      portfolioId,
      title,
      description,
      ampIdentifier
    );

    if (response.ok) {
      mutate(
        `portfolios/${portfolioId}/activities?searchString=${searchString}&page=${page}&pagesize=${pageSize}`
      );

      mutate(`portfolios/${portfolioId}/allresults`);
      mutate(`portfolios/allactivities/${portfolioId}`);
      mutate(
        `portfolios/${portfolioId}/results?page=${page}&pageSize=${pageSize}&searchString=${searchString}`
      );
    }
    handleAddActivityModalClose();
  };

  const handleRemoveActivityConfirm = async () => {
    let removeResponse = await callApi(
      `activities/${activityToBeRemoved.id}/${portfolioId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!removeResponse.ok) {
      const jsonResponse = await removeResponse.json();
      addToast({
        variant: "error",
        message: jsonResponse.error[0],
      });
    } else {
      mutate(
        `portfolios/${portfolioId}/activities?searchString=${searchString}&page=${page}&pagesize=${pageSize}`
      );

      mutate(`portfolios/${portfolioId}/allresults`);
      mutate(`portfolios/allactivities/${portfolioId}`);
      mutate(
        `portfolios/${portfolioId}/results?page=${page}&pageSize=${pageSize}&searchString=${searchString}`
      );

      handleRemoveActivityClose();
    }
  };

  const handleDeleteActivity = async () => {
    let response = await deleteActivity(activityToBeDeleted);

    if (response.ok) {
      mutate(
        `portfolios/${portfolioId}/activities?searchString=${searchString}&page=${page}&pagesize=${pageSize}`
      );

      mutate(`portfolios/${portfolioId}/allresults`);
      mutate(`portfolios/allactivities/${portfolioId}`);
      mutate(
        `portfolios/${portfolioId}/results?page=${page}&pageSize=${pageSize}&searchString=${searchString}`
      );

      handleDeleteActivityModalClose();
    }
  };

  const handleNestedPortfolioConfirmation = async (
    portfolioUniqueTitle,
    portfolioTitle
  ) => {
    let response = await callApi(
      `portfolios/${portfolioUniqueTitle}/circularnesting/${portfolioId}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      setShowNestingError(true);
    } else {
      setShowNestingError(false);
      setPortfolioUniqueTitleForNesting(portfolioUniqueTitle);
      setPortfolioTitleForNesting(portfolioTitle);
      setNestingPortfolioModalConfirmationOpen(true);
    }
  };

  const handleShareActivitiesSubmit = async (
    portfolioUniqueTitle,
    userChoice,
    shareAll
  ) => {
    if (shareAll) {
      const response = await callApi(
        `portfolios/${portfolioId}/activities/share-all?destinationPortfolioUniqueTitle=${portfolioUniqueTitle}&nestedChoice=${userChoice}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setNestedPortfolioRemovedorAdded(!nestedPortfolioRemovedorAdded);
        setIsShareActivitiesModalOpen(false);
        setNestingPortfolioModalConfirmationOpen(false);
        setPortfolioUniqueTitleForNesting(null);
        setPortfolioTitleForNesting(null);
      }
    }
  };

  const handleShareActivitySubmit = async (
    activityId,
    portfolioUniqueTitle,
    userChoice
  ) => {
    let shareResponse = await callApi(
      `activities/${activityId}/${portfolioUniqueTitle}/${portfolioId}/${userChoice}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (shareResponse.ok) {
      handleShareActivityModalConfirmationClose();
      /*mutate(
        `portfolios/${portfolioId}/activities?searchString=${searchString}&page=${page}&pagesize=${pageSize}`
      );*/

      mutate(`portfolios/${portfolioId}/allresults`);
      //mutate(`portfolios/allactivities/${portfolioId}`);
      mutate(
        `portfolios/${portfolioId}/results?page=${page}&pageSize=${pageSize}&searchString=${searchString}`
      );
    }
  };
  const handleShareActivityModalClose = () => {
    setActivityToBeShared(null);
    setIsShareActivityModalOpen(false);
  };

  const handleShareActivityModalConfirmationClose = () => {
    setActivityToBeShared(null);
    setPortfolioUniqueTitle(null);
    setIsShareActivityModalOpenConfirmation(false);
  };

  const handleShareActivityModalOpenConfirmation = () => {
    setIsShareActivityModalOpenConfirmation(true);
    setIsShareActivityModalOpen(false);
  };

  const handleRequestAccess = async () => {
    let response = await callApi(
      `activities/${requestActivityId}/requestaccessforsharedactivity/${portfolioId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      hideActivityRequestAccessModal();
    }
  };

  const hideActivityRequestAccessModal = () => {
    setRequestActivityId(0);
    setRequestAccessModalConfirmationOpen(false);
  };

  const handleRequestAccessForPortfolio = async () => {
    let response = await callApi(
      `portfolios/${requestPortfolioId}/requestaccessfornestedportfolio/${portfolioId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      hidePortfolioRequestAccessModal();
    }
  };

  const hidePortfolioRequestAccessModal = () => {
    setRequestPortfolioId(0);
    setRequestAccessModalConfirmationOpenForPortfolio(false);
  };

  const handleClickUnnestPortfolio = (item) => {
    setNestingPortfolioIdForRemove(item.portfolioViewModel.id);
    setPortfolioRemovingFrom(portfolio.title);
    setIsRemoveingNestingPortfolioModalOpenConfirmation(true);
  };

  const hideRemovePortfolioModal = () => {
    setIsRemoveingNestingPortfolioModalOpenConfirmation(false);
    setNestingPortfolioIdForRemove(null);
    setPortfolioRemovingFrom("");
  };

  const handleRemovePortfolioConfirmation = async () => {
    let shareResponse = await callApi(
      `portfolios/${portfolioId}/${nestingPortfolioIdForRemove}/removenestingfromactivity`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (shareResponse.ok) {
      setNestedPortfolioRemovedorAdded(!nestedPortfolioRemovedorAdded);
      hideRemovePortfolioModal();
      mutate(`portfolios/${portfolioId}/allresults`);
    }
  };

  const handlePortfolioRequestClick = (portfolioId) => {
    setRequestPortfolioId(portfolioId);
    setRequestAccessModalConfirmationOpenForPortfolio(true);
  };

  return (
    <>
      <h2 className="h2">Activities</h2>

      <div className="actions mb-4">
        {isUserPortfolioOwner && (
          <div className="icon-and-text" onClick={handleAddActivityModalOpen}>
            <i className="icon bi bi-plus-circle"></i>
            Add Activity
          </div>
        )}
        {isUserPortfolioOwner && (
          <div
            className="icon-and-text has-left-border"
            onClick={handleShareActivitiesModalOpen}
          >
            <i className="icon bi bi-list-nested"></i>
            Nest Portfolio
          </div>
        )}
      </div>
      <div className="filter-activities mt-4 mb-5">
        <label className="bold">Find an activity</label>
        <Form.Control
          type="text"
          onChange={(e) => {
            setPage(1);
            setSearchString(e.target.value);
          }}
        />
      </div>

      {isLoadingActivities ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : thisPortfolioActivities.length ||
        sharedPortfolios.length ||
        groupActivity !== undefined ? (
        <>
          {
            <>
              {isUserPortfolioOwner && (
                <>
                  <Link
                    to={`${match.url}/${groupActivity.id}`}
                    className="icon-and-text"
                  >
                    <i className="icon bi bi-collection"></i>
                    {groupActivity.title}
                  </Link>
                </>
              )}
            </>
          }
          {thisPortfolioActivities.length > 0 && (
            <h4 className="h4 mt-4 mb-3">This portfolio</h4>
          )}
          <ul className="list-group activities-list-group">
            {thisPortfolioActivities.map((value, index) => (
              <ActivityListItem
                key={value.id}
                activity={value}
                onClickDeleteActivity={handleClickDeleteActivity}
                onClickEditActivity={handleClickEditActivity}
                onClickShareActivity={handleClickShareActivity}
                onClickRemoveActivity={handleClickRemoveActivity}
                isUserPortfolioOwner={isUserPortfolioOwner}
                handleGotoActivityHomePortfolio={
                  handleGotoActivityHomePortfolio
                }
              />
            ))}
            {uniquePortfolioNames.map((item, index) => (
              <ul key={item} className="list-group activities-list-group mb-4">
                <h4 className="h4 mt-5 mb-3">From {item}</h4>
                {sharedPortfolios
                  .filter((x) => x.originatingPortfolioTitle === item)
                  .map((v, i) => (
                    <ActivityListItem
                      key={v.id}
                      activity={v}
                      onClickDeleteActivity={handleClickDeleteActivity}
                      onClickEditActivity={handleClickEditActivity}
                      onClickShareActivity={handleClickShareActivity}
                      onClickRemoveActivity={handleClickRemoveActivity}
                      isUserPortfolioOwner={isUserPortfolioOwner}
                      onClickGotoActivityHomePortfolio={
                        handleGotoActivityHomePortfolio
                      }
                    />
                  ))}
              </ul>
            ))}
            <Pager
              totalItemCount={totalItemCount}
              pageSize={pageSize}
              page={page}
              onPageChanged={setPage}
            />
          </ul>
        </>
      ) : searchString.length ? (
        <span className="text-muted">No activities found</span>
      ) : (
        <span className="text-muted">
          There are no activities in this portfolio
        </span>
      )}
      {isUserPortfolioOwner && (
        <>
          {nestedPortfolioActivities !== undefined &&
            nestedPortfolioActivities.length > 0 && (
              <>
                <h4 className="h4 mt-4 mb-3">Nested portfolios</h4>

                <>
                  {nestedPortfolioActivities.map((item, index) => (
                    <Collapsible
                      label={item.portfolioViewModel.title}
                      className="collapsible"
                    >
                      <>
                        {item.hasAccess ? (
                          <div className="mt-2">
                            <NestingPortfolioPopover
                              isUserPortfolioOwner={isUserPortfolioOwner}
                              onClickRemove={() =>
                                handleClickUnnestPortfolio(item)
                              }
                              onClickGotoHome={() =>
                                handleClickGotoHomePortfolio(item)
                              }
                            ></NestingPortfolioPopover>

                            <NestedPortfolioActivity
                              item={item}
                              setRequestActivityId={setRequestActivityId}
                              setRequestPortfolioId={setRequestPortfolioId}
                              setRequestAccessModalConfirmationOpen={
                                setRequestAccessModalConfirmationOpen
                              }
                              setRequestAccessModalConfirmationOpenForPortfolio={
                                setRequestAccessModalConfirmationOpenForPortfolio
                              }
                            ></NestedPortfolioActivity>
                          </div>
                        ) : (
                          <div>
                            <span>
                              You do not have access to this portfolio.
                              <Link
                                onClick={() =>
                                  handlePortfolioRequestClick(
                                    item.portfolioViewModel.id
                                  )
                                }
                              >
                                Contact owner to request access or change
                                nesting type to open{" "}
                              </Link>
                            </span>
                          </div>
                        )}
                      </>
                    </Collapsible>
                  ))}
                </>
              </>
            )}
        </>
      )}

      <AddActivityModal
        isOpen={isAddActivityModalOpen}
        onSubmit={handleNewActivitySubmit}
        onHide={handleAddActivityModalClose}
      />
      <EditActivityModal
        isOpen={isEditActivityModalOpen}
        onSubmit={handleEditActivitySubmit}
        onHide={handleEditActivityModalClose}
        activity={activityToBeEdited}
        onChangeProperty={handleSetEditedActivityProperty}
      />
      <ShareActivityModal
        isOpen={isShareActivityModalOpen}
        onSubmit={handleShareActivityModalOpenConfirmation}
        onHide={handleShareActivityModalClose}
        activity={activityToBeShared}
        onSetPortfolioUniqueTitle={setPortfolioUniqueTitle}
      />
      <ShareActivityModalConfirmation
        isOpen={isShareActivityModalOpenConfirmation}
        onSubmit={handleShareActivitySubmit}
        onHide={handleShareActivityModalConfirmationClose}
        activity={activityToBeShared}
        portfolioUniqueTitle={portfolioUniqueTitle}
        previouslySharedPortfoliosOfActivity={
          previouslySharedPortfoliosOfActivity
        }
      />

      <ShareActivitiesModal
        portfolioId={portfolioId}
        isOpen={isShareActivitiesModalOpen}
        onSubmit={handleNestedPortfolioConfirmation}
        onHide={handleShareActivitiesModalClose}
        showError={showNestingError}
      />
      <NestedPortfolioModalConfirmation
        isOpen={nestingPortfolioModalConfirmationOpen}
        nestedPortfolioActivities={nestedPortfolioActivities}
        portfolioUniqueTitle={portfolioUniqueTitleForNesting}
        portfolioTitle={portfolioTitleForNesting}
        onSubmit={handleShareActivitiesSubmit}
        onHide={() => setNestingPortfolioModalConfirmationOpen(false)}
        currentPortfolioTitle={portfolio !== undefined ? portfolio.title : ""}
      />
      <RequestAccessModalConfirmation
        isOpen={requestAccessModalConfirmationOpen}
        onHide={hideActivityRequestAccessModal}
        onSubmit={handleRequestAccess}
      />
      <RequestAccessModalConfirmation
        isOpen={requestAccessModalConfirmationOpenForPortfolio}
        onHide={hidePortfolioRequestAccessModal}
        onSubmit={handleRequestAccessForPortfolio}
      />
      <RemoveNestedPortfolioModalConfirmation
        isOpen={isRemoveingNestingPortfolioModalOpenConfirmation}
        onSubmit={handleRemovePortfolioConfirmation}
        onHide={hideRemovePortfolioModal}
        portfolioRemovingFrom={portfolioRemovingFrom}
      />
      <Modal
        show={isDeleteActivityModalOpen}
        onHide={handleDeleteActivityModalClose}
      >
        <Modal.Header>
          <Modal.Title>Delete Activity</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={handleDeleteActivityModalClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this activity?</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="button primary" onClick={handleDeleteActivity}>
            Delete Activity
          </button>
          <button
            type="button"
            className="button tertiary"
            onClick={handleDeleteActivityModalClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={isRemoveActivityModalOpen}
        onHide={handleRemoveActivityClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Activity</Modal.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            onClick={handleRemoveActivityClose}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to remove this activity?</p>
          <p>
            This will only remove this activity from this portfolio. The
            activity will not be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button primary"
            onClick={handleRemoveActivityConfirm}
          >
            Remove Activity
          </button>
          <button
            type="button"
            className="button tertiary"
            onClick={handleRemoveActivityClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
